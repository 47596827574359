
input[type=text],
input[type=search] {
  margin: 1rem 0;
  padding: 0.5rem;
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  transition: all 0.1s;

  &:focus {
    outline: none;
    box-shadow: 0 0.25rem 0 0.125rem rgba(0, 0, 0, 0.1);
  }

  &:not(:placeholder-shown) {
    border-color: rgba(0, 0, 0, 0.9);
  }
}

select {
  background: $background-color;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0.5rem;

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  &::after {
    content: '\2193';
  }
}
