
.image-container:empty {
  display: none;
}

.image-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  margin: 2rem;
  max-height: 20rem;
}

.image-container > img {
  flex: 0 1 auto;
  min-width: 0;
  min-height: 0;
  max-width: 100%;
  max-height: 20rem;
}

.single-image-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.single-image-container > img {
  max-height: 20rem;
  object-fit: contain;
}
