@charset 'UTF-8';

@import 'fonts';

@import 'common/variables';
@import 'common/mixins';

@import 'base';

@import 'components/buttons';
@import 'components/flex';
@import 'components/footer';
@import 'components/grid';
@import 'components/images';
@import 'components/inputs';
@import 'components/links';
@import 'components/lists';
@import 'components/menu';
@import 'components/publications';
@import 'components/tables';
@import 'components/typography';

@import 'media/mobile';

.highlight-audio-and-acoustics { background: #f75c03; }
.highlight-intelligent-systems { background: #d90368; }
.highlight-nonlinear-signal-processing { background: #f1c40f; }
.highlight-speech-communication { background: #2274a5; }
.highlight-wireless-communications { background: #00cc66; }

.small-square { height: 1rem; width: 1rem; flex-shrink: 0; }
.smaller-square { height: 0.75rem; width: 0.75rem; flex-shrink: 0; }

.with-a-littler-margin { margin: 0.25rem 0.25rem 0 0; }
.with-a-little-margin { margin: 0.5rem 0.5rem 0 0; }
.with-a-little-margin:only-child { margin-right: 0.5rem; }

svg.iconify:first-of-type { margin-top: 0; }

.item-with-squares {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
}

.squares-holder {
  display: flex;
  flex-direction: column;
  width: 1.5rem;
}

details {
  margin-bottom: 1.6rem;

  & > summary {
    font-weight: 400;
  }

  & > ul > li {
    margin-bottom: 0.8rem;
  }
}

nav > svg {
  pointer-events: none;
  margin-top: 1rem;
}

