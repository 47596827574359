footer {
  border-top: 1px solid $primary-color;
  margin: 5rem auto 0 auto;
  padding: 2.5rem 0;

  max-width: $body-max-width;
  
  display: flex;
  flex-direction: row;
  justify-content: center;

  & > a.footer-link {
    color: $muted-color;
    border-color: $muted-color;
  }

  & > a.footer-link:hover {
    color: $background-color;
    background-color: $muted-color;
  }

}
