
table {
  border-collapse: collapse;
}

th {
  text-align: left;
  font-weight: $bold-font-weight;
  border-bottom: 1px solid currentColor;
  padding: 0.5rem;
}

tr {
  &:nth-child(2n) {
    background-color: #f0f0f0;
  }
}

td {
  padding: 0.25rem 0.5rem;
  border: 1px solid #fff;
}

.dataTables_wrapper label input {
  margin: 0;
}

.dataTables_wrapper table thead tr th {
  font-weight: 400;
}

.paginate_button {
  border-radius: 0 !important;
  background: $background-color !important;

  &:hover {
    border-color: transparent !important;
    background: $primary-color !important;
  }

  &.current {
    border: 1px solid $primary-color !important;

    &:hover {
      background: #ccc !important;
    }
  }
}

table.centered-66 {
  width: 66%;
  margin: 0 auto;
}