
header {
  position: sticky;
  background: rgba(255, 255, 255, 0.85);
  height: 8rem;
  top: 0;
  z-index: 100;
}

header > nav {
  padding: 0.75rem 0;
  width: $body-max-width;
  margin: 0 calc((100% - 80rem)/2);
  border-bottom: 1px solid #333;
  height: 4rem;
}

.submenu-parent {
  position: relative;
}

.submenu {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 100%;
  left: 0.125rem;
  width: 100%;
  z-index: -1;
  transition: all 0.1s;
  box-shadow: 0px 0.5rem 0px 0.5rem rgba(255, 255, 255, 0.85);
}

.submenu-parent:focus .submenu,
.submenu-parent:focus-within .submenu,
.submenu-parent:hover .submenu {
  visibility: visible;
  opacity: 1;
  z-index: 1;
}

ul.main-menu,
ul.submenu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

ul.main-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

ul.submenu {
  background: #fff;
  width: 20rem;
  border: 1px solid #333;
  padding: 1rem;
}

ul.submenu > li > a,
ul.submenu > li > ul > li > a {
  display: inline-block;
  width: 100%;
  border: 0;
  line-height: 2;
}

li.submenu-parent > a {
  border: 0;
  font-size: 1.25rem;
  padding: 0.25rem 0.5rem;
}

#title-holder {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

#home-link {
  border: none;
  font-size: 1.5rem;
  padding: 0.5rem;
  transition: all 0.2s;

  &:hover {
    color: #fff;
  }
}

#tug-logo {
  height: 4rem;
}

#areas-link-wrapper {
  display: flex;
  align-items: center;
  height: 4rem;
}

#areas-link {
  display: flex;
  flex-direction: row;
}

#areas-link a {
  transition: 0.1s all;
  overflow: hidden;
  color: rgba(0, 0, 0, 0);
}

#areas-link:hover a {
  width: 0;
}

#areas-link a:hover {
  width: 12rem;
  height: 3rem;
  color: #fff;
  text-decoration: none;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  font-size: 0.875rem;
}

#areas-link a.small-square {
  margin-right: 0.5rem;
}

#areas-link a.small-square:last-of-type {
  margin-right: 0;
}

#areas-link:not(:hover) a.small-square:not(:hover) {
  width: 1.5rem;
  height: 1.5rem;
}
