
@media screen and (max-width: 768px) {
  main {
    max-width: unset;
    margin: 1rem;
  }

  header {
    height: 4rem;

    & > nav {
      padding: 0.75rem 0;
      width: unset;
      margin: 0 auto;
      border-bottom: 1px solid #333;
      height: 4rem;
    }
  }

  #title-holder {
    display: none;
  }
  
  li.submenu-parent > a {
    font-size: 1rem;
  }

  .submenu {
    position: fixed;
    top: 4rem;
    left: 50%;
    width: 100%;
    z-index: -1;
    transition: all 0.1s;
    box-shadow: 0px 0px 0px 0.5rem rgba(255, 255, 255, 0.85);
    transform: translateY(0.5rem);
  }

  ul.submenu {
    margin: 0 0 0 -10rem;
  }

  .flex-columns {
    flex-direction: column;
  }

  .grid-3 {
    display: flex;
    flex-direction: column;
  }

  .grid-5 {
    display: flex;
    flex-direction: column;
  }

  /*  DataTables.js-related */
  span.dtr-title {
    font-weight: 400 !important;
    display: block !important;
  }

  nav.filter {
    display: none;
    flex-direction: column;
    align-items: baseline;
    margin-bottom: 1.6rem;

    & > .options-holder {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      & > a {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .only-mobile {
    display: flex;
  }
}
