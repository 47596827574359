
$font-stack: 'Fira Sans', sans-serif;
$base-font-size: 14px;
$base-font-weight: 400;
$bold-font-weight: 700;
$base-line-height: 1.6;

$primary-color: #333;
$muted-color: #666;
$background-color: #fff;

$body-max-width: 80rem;
$body-max-width-wide: 100rem;

$break-small: 600px;
$break-large: 1200px;
