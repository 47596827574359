/* fira-sans-regular - latin-ext_latin_greek */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/fira-sans-v15-latin-ext_latin_greek-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/fira-sans-v15-latin-ext_latin_greek-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/fira-sans-v15-latin-ext_latin_greek-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/fira-sans-v15-latin-ext_latin_greek-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/fira-sans-v15-latin-ext_latin_greek-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/fira-sans-v15-latin-ext_latin_greek-regular.svg#FiraSans') format('svg'); /* Legacy iOS */
}

/* fira-sans-italic - latin-ext_latin_greek */
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/fira-sans-v15-latin-ext_latin_greek-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/fira-sans-v15-latin-ext_latin_greek-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/fira-sans-v15-latin-ext_latin_greek-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/fira-sans-v15-latin-ext_latin_greek-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/fira-sans-v15-latin-ext_latin_greek-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/fira-sans-v15-latin-ext_latin_greek-italic.svg#FiraSans') format('svg'); /* Legacy iOS */
}

/* fira-sans-700 - latin-ext_latin_greek */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/fira-sans-v15-latin-ext_latin_greek-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/fira-sans-v15-latin-ext_latin_greek-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/fira-sans-v15-latin-ext_latin_greek-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/fira-sans-v15-latin-ext_latin_greek-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/fira-sans-v15-latin-ext_latin_greek-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/fira-sans-v15-latin-ext_latin_greek-700.svg#FiraSans') format('svg'); /* Legacy iOS */
}

