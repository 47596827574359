
.publication-listing {
  display: flex;
  flex-direction: column;
}

.publication-item {
  display: flex;
  flex-direction: row;
  
  @include margin-bottom(1);
}

.publication-lhs {
  flex-grow:   0;
  flex-shrink: 1;
  flex-basis:  80%;
}

.publication-rhs {
  text-align: right;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
}

.filter-holder {
  display: flex;
  flex-direction: column;

  border: 1px solid rgba(223, 223, 223, 0);
  transition: all 0.2s;
}

.filter-holder.active {
  border: 1px solid rgba(0, 0, 0, 0.9);
}

.collapsable {
  overflow: hidden;
  transition: all 0.3s ease-out;
  height: auto;
}

#typeSelector, #authorSelector, #yearSelector, #journalSelector {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

