
@mixin line-height($number) {
  line-height: #{ $number * $base-line-height + 'rem' };
}

@mixin margin-top($number) {
  margin-top: #{ $number * $base-line-height + 'rem' };
}

@mixin margin-bottom($number) {
  margin-bottom: #{ $number * $base-line-height + 'rem' };
}
