
button[type="button"] {
  color: $muted-color;
  background-color: rgba(0, 0, 0, 0);

  font-weight: 300;
  text-decoration: none;

  padding: 0.5rem;
  margin: 0.25rem;

  transition: all 0.1s;

  &:not(.bordered) {
    border: none;
  }

  &:hover {
    color: #000;
    background-color: rgba(0, 0, 0, 0.1);
  }

  &:active {
    color: $background-color;
    background-color: $primary-color;
  }

  &:focus {
    outline: none;
  }

  &.active {
    color: #000;
    background-color: #fff;

    box-shadow: 0 0.25rem 0 0.125rem rgba(0, 0, 0, 0.1);
    position: relative;
    top: -0.25rem;
  }
}

button[type="button"].bordered {
  border: 1px solid rgba(223, 223, 223, 1);

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.95);
  }

  &:active {
    border: 1px solid rgba(255, 255, 255, 0);
  }

  &.active {
    border: 1px solid $primary-color;
  }
}
