
* {
  box-sizing: border-box;
}

html {
  font-family: $font-stack;
  font-size:   $base-font-size;
  line-height: $base-line-height;
  font-weight: $base-font-weight;
}

body {
  transition: all 0.1s;
}

main {
  max-width: $body-max-width;
  margin:    1rem auto;
}

main.wide {
  max-width: 1.25 * $body-max-width;
  margin: 1rem auto;
}

nav > dl > dd {
  margin: 0;
}

hr {
  border: 0;
  height: 1px;
  background: #333;
  margin: 5rem;
}

nav.filter {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 1.6rem;

  & > h4 {
    flex-basis: 15%;
  }

  & > .options-holder {
    flex-basis: 85%;
    display: flex;
    flex-wrap: wrap;
  }
}

.only-mobile {
  display: none;
}

/* fix for targetting footnote links, due to header taking up vertical space */
sup:target {
  padding-top: 10rem;

  & > a {
    background-color: #333;
    color: #fff;
  }
}

