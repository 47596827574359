$headings: (
  h1: (2, 3, 1),
  h2: (1.5, 2, 1),
  h3: (1.5, 1, 0),
  h4: (1, 1, 0),
  h5: (1, 1, 0),
  h6: (1, 1, 0)
);

@each $heading, $properties in $headings {
  #{$heading} {
    font-weight: $bold-font-weight;

    &:first-child {
      margin-top: 0;
    }

    &:not(:first-child) {
      @include line-height(nth($properties, 1));
      @include margin-top(nth($properties, 2));
      @include margin-bottom(nth($properties, 3));
    }

    &:target {
      color: red;
    }

    &:target::before {
      content: '';
      display: block;
      height: 8rem;
      margin-top: -8rem;
    }
  }
  
  span.breadcrumbs + #{$heading} {
    @include margin-top(0);
  }
}

header > h1 {
  @include margin-top(1);
  @include margin-bottom(1);
}

h1 > a {
  border: 0;
}

nav > h4 {
  @include margin-top(0);
  @include margin-bottom(0);
}

p {
  text-align: justify;
  @include margin-bottom(1);
  @include margin-top(0);
}

p:empty {
  display: none;
}

.muted {
  color:      $muted-color;
  font-size:  0.875rem;
  font-style: italic;
}

.breadcrumbs {
  color: $muted-color;
  font-size: 0.75rem;
}

.breadcrumbs > a {
  color: $muted-color;
  border-color: $muted-color;
}

.breadcrumbs > a:hover {
  color: $background-color;
  background-color: $muted-color;
  border-color: $background-color;
}

blockquote {
  margin: 1rem 0;
  padding: 1rem 5rem;
  border-left: 0.25rem solid $muted-color;
  background-color: rgba(0, 0, 0, 0.025);

  &.danger {
    border-color:     rgba(255, 64, 32, 0.5);
    background-color: rgba(255, 64, 32, 0.05);
  }

  &.info {
    border-color:     rgba(64, 32, 255, 0.5);
    background-color: rgba(64, 32, 255, 0.05);
    color:            rgba(32, 16, 128, 0.9);

    & > a {
      border-color: rgba(32, 16, 128, 0.5);
      color:        rgba(32, 16, 128, 0.75);

      &:hover {
        color: rgba(255, 248, 232, 1);
        background-color: rgba(32, 16, 128, 0.5);
      }
    }
  }
}

pre {
  margin:  (0.8rem - 0.25rem) 1rem;
  padding: 0.8rem 1rem;
  border-top:    0.25rem solid $muted-color;
  border-bottom: 0.25rem solid $muted-color;

  color: $background-color;
  background-color: $primary-color;
}

