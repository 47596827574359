
a:not(.plain) {
  color:           $primary-color;
  text-decoration: none;
  border-bottom:   1px solid $primary-color;
  padding:         0.125rem 0.25rem;
  margin:          0 0.125rem;
  transition:      0.1s all;

  &:hover {
    color:            $background-color;
    background-color: $primary-color;
    border-color:     $background-color;
  }
}

a.plain {
  color: $primary-color;
  text-decoration: none;

  border: none;

  &:hover {
    color: $primary-color;
    border: none;
  }

  &:active {
    border: none;
  }
}

a.button {
  color:           $primary-color;
  text-decoration: none;

  padding:         0.125rem 0.25rem;

  transition: 0.1s all;

  &:hover {
    color:            $background-color;
    background-color: $primary-color;
  }
}

a.toggle {
  cursor: pointer;
  padding: 0.125rem 0.25rem;

  border-style: solid;
  border-width: 1px;
  border-color: transparent transparent #888 transparent;

  &.active {
    border: 1px solid $primary-color;
    background-color: $background-color;
    box-shadow: 0 0.25rem 0 0.125rem rgba(0, 0, 0, 0.1);

    &:hover {
      color: $primary-color;
    }
  }
}

.area-link {
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.plain.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:hover {
    & > .image-caption-link > .caption {
      color: #fff;
      opacity: 1;
    }
    
    & > .image-caption-link > img {
      filter: blur(0.25rem);
    }
  }
}

.image-caption-link {
  position: relative;
  text-align: center;

  & > .caption {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;

    font-size: 1.5rem;
    color: #fff;

    &.nopic {
      color: #333;
      opacity: 1;
    }
  }

  &:hover > .caption {
    opacity: 1;
  }
}
