
ul {
  list-style-type: circle;
  list-style-position: outside;
  padding: 0;

  margin-left: 0.8rem;
  padding-left: 1.6rem;
  
  @include margin-top(0);
  @include margin-bottom(1);
}

p > ul {
  @include margin-top(1);
}

ul:empty::after {
  content: 'empty';
  color: $muted-color;
}

dl {
  margin: 0 0 1rem 0;
  padding: 0;
}

dt {
  margin: 0;
  padding: 0;
  font-weight: $bold-font-weight;
}

dd {
  margin-left: $base-line-height * 1rem;
  @include margin-bottom(1);
}

dd > ul {
  margin: 0;
  padding: 0;
}

ul.publications-list > li {
  margin-bottom: 0.8rem;
}
