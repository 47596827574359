
.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.grid-5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.grid-item {
  margin: 0.5rem;
  padding: 0.5rem;

  &.centered {
    text-align: center;
  }
}

.grid-item-small {
  margin: 0.5rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 5rem;
}

.grid-item-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: $bold-font-weight;
  margin-bottom: 0.5rem;
  min-height: $base-line-height * 1rem * 2;
  
  &.centered {
    justify-content: center;
  }
}

.grid-3 a,
.grid-5 a {
  border: 0;
}

