
.flex-columns {
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
}

.flex-rows {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}

.flex-33 {
  flex-basis: 33%;
  margin: 0.5rem;
}

.flex-66 {
  flex-basis: 66%;
  margin: 0.5rem;
}
